import location from "./icons8-address-50.png";
import telephone from "./icons8-ringer-volume-50.png";
import home from "./icons8-home-50.png";
import world from "./icons8-world-50.png";

export const imgs = {
  location,
  telephone,
  home,
  world,
};
